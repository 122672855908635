import { Global, Jobs, Collection } from '@sourceflow-uk/sourceflow-sdk';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import Link from 'next/link';
import SeoHead from '../components/SeoHead';
import MainBanner from '../components/MainBanner';
import TextWithCTA from '../components/TextWithCTA';
import LatestJobs from '../components/LatestJobs';
import LatestNews from '../components/LatestNews';
import Header from '../components/MainHeader';
import AllPartners from '../components/AllPartners';

export default function Home({
  global,
  all_jobs,
  all_news,
  all_partners
}) {
  return (
    <>
      <SeoHead />
      <Header
        enableNav={false}
      />
      <MainBanner
        global={global}
        pathPrefix={`homepage.text_with_cta`}
        customClass={``}
        link1={`/jobs/`}
        link2={`/lets-get-started/`}
        heading1={`Job seekers`}
        heading2={`Employers`}
        subtitle1={`Find out more`}
        subtitle2={`Find out more`}
        imageSrc1={`/site-assets/images/job-seeker-banner.webp`}
        imageSrc2={`/site-assets/images/employer-banner.webp`}
      />
      <AllPartners
        global={global}
        pathPrefix={`homepage.all_partners`}
        heading={`Looking for one of our partner sites?`}
        customClass={'pt-5 pb-0 pb-md-5 mt-4'}
        post={all_partners}
      />
      <TextWithCTA
        global={global}
        pathPrefix={`homepage.text_with_cta`}
        customClass={`mt-5 mb-5`}
        link1={`/job-seekers-get-in-touch`}
        link2={`/recruit-staff/`}
        buttonTitle1={
          <>
            <strong>
              Get a callback
            </strong> and we'll help you find your next role
          </>
        }
        buttonTitle2={
          <>
            <strong>
              Recruit staff
            </strong> let us help your fill your next role
          </>
        }
      />
      <LatestJobs
        global={global}
        pathPrefix={`homepage.latest_jobs`}
        customClass={`mt-3 mb-5`}
        buttonTitle={`view all jobs`}
        buttonLink={`/jobs/`}
        heading={`Find work that suits you`}
        subtitle={`jobseekers`}
        label={false}
        all_jobs={all_jobs}
      />
      <LatestNews
        global={global}
        pathPrefix={`homepage.latest_news`}
        customClass={`mb-4`}
        heading={`News and insights`}
        all_news={all_news}
        buttonTitle={`Read`}
        pagePrefix={``}
      />
    </>
  )
}

export async function getStaticProps({ params }) {
  const global = (await Global()).toJson();
  const news = await Collection('news');
  const partners = await Collection('partners');

  const all_jobs = (await Jobs())
    .filter(function (job) {
      return job.categories
        // Pulls all jobs except internal jobs
        .find((cat) => cat.id !== "eabbc9bc-c6e8-4bd0-861d-b129f5218f97");
    })
    .filter(function (job) {
      return (
        (new Date(job?.expires_at)) >= (new Date())
      )
    })
    .getItems().slice(0, 8);

  const all_news = news.orderBy('publish_date').getItems().slice(0, 3);
  const all_partners = partners.orderBy('order').getItems();

  return {
    props: {
      global,
      all_jobs,
      all_news,
      all_partners
    }
  };
}
